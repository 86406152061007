<template>
  <div 
    v-if="needToDisplay"
    class="product-card__selling-proposition-container"
  >
    <!-- 榜单 单独展示 优先级最高 -->
    <ProductCardSellingPropositionRank
      v-if="rankingList && !addRankingLabelToSwiper"
      :ranking-list="rankingList"
    />
    
    <!-- 非榜单，取前两个轮播 -->
    <Swiper
      v-else-if="swiperLabelList.length > 0"
      class="product-card__selling-proposition-swiper not-fsp-element"
      :delay-loop-init-time="1500"
      :delay="3000"
      direction="vertical"
      intersection-observer
    >
      <ProductCardSellingPropositionSlideItem
        v-for="itemData in swiperLabelList"
        :key="itemData.random"
        :item-data="itemData"
      >
        <!-- 榜单标签加入轮播 只有 item.type === 'slot' 才会被渲染  -->
        <ProductCardSellingPropositionRank
          :style="{
            '--selling-rank-height': '14px', '--selling-rank-margin-top': '0'
          }"
          :ranking-list="rankingList"
        />
      </ProductCardSellingPropositionSlideItem>
    </Swiper>
  </div>
</template>

<script name="ProductCardSellingPropositionContainer" setup>
import { template } from '@shein/common-function'

import ProductCardSellingPropositionRank from './ProductCardSellingPropositionRank.vue'
import Swiper from 'public/src/pages/components/Swiper/Swiper.vue'
import ProductCardSellingPropositionSlideItem from './ProductCardSellingPropositionSlideItem.vue'

import { ref, inject, watch, computed, toRefs } from 'vue'
import { labelTypeMap } from '../../utils/constant.js'

const labelsFromKey = inject('labelsFromKey')

// 标题下方  商品项卖点容器
// 展示逻辑： 有榜单的时候展示榜单，没有则所有标签以轮播形式展示

const props = defineProps({
  goodsInfo: {
    type: Object,
    default: () => ({}),
  },
  // 插槽单独使用，非商卡配置项
  showStarCommentSlot: {
    type: Boolean,
    default: false
  },
})

const { goodsInfo } = toRefs(props)

// 轮播数据处理 start
const textDataLabel = ({ text, fontStyle, style = {}, suiIcon }) => {
  return {
    type: 'text',
    random: Math.random(),
    style: style,
    textData: {
      text,
      fontStyle,
      suiIcon,
    }
  }
}

/** 
 * 处理文本标签数据
 * 包含以下类型
 *  低库存 用户行为标签 用户评论关键词 优质评论 商品属性 人工卖点
 * 
 * @returns {Array} { textData: 文字数据 }
 * textData { text: 展示的文字, fontStyle: 字体颜色 gary灰  golden金 }
*/
const handelLabelData = () => {
  const { pretreatInfo = {} } = goodsInfo.value ?? {}
  const { IS_RW } = constantData
  
  const { 
    showStarComment,
    showDecisionAttr, 
    showSellingPoint,
    showLeftStock,
    showUserBehaviorLabel,
    showUserPreferencesLabel,
    showQuickShip
  } = config ?? {}

  const { stockLeft } = pretreatInfo
  const { sellingPointUniversalLabels } = pretreatInfo[labelsFromKey] ?? {}

  const swiperLabelList = []
  // <!-- TODO: 低库存 剩余库存（仅romwe）0-10(不含首尾) 例: only 2 left -->
  if (IS_RW && showLeftStock && stockLeft) {
    swiperLabelList.push(
      textDataLabel({ text: stockLeft, fontStyle: 'golden' })
    )
  }

  if (!sellingPointUniversalLabels?.length) {
    return {
      swiperLabelList: [],
    }
  }

  // 因轮播有优先级取最前面两个，接口返回数组形式，里面只有一个字段，按数组顺序取
  // sellingPointUniversalLabels: [
  //   {
  //     starComment: {},  // 通用角标
  //   },
  //   {
  //     userBehaviorLabels: {

  //       "userBehaviorLabel": {
  //         "backgroundColor": "#7ed321",
  //         "fontColor": "#666666",
  //         "icon": "",
  //         "labelLang": "近30天点击PV205不分挡位",
  //         "labelLangEn": "近30天点击PV205不分挡位",
  //         "labelId": "205"
  //       }
  // }, 
  //   }
  // ]
  // 在上报时需要额外上报一次的标签
  const extraTagProposition = []

  const anaText = []

  sellingPointUniversalLabels?.slice(0, 2 - swiperLabelList.length).map(({ 
    quickship,
    starComment,
    userPreferencesLabel,
    userBehaviorLabel, 
    userCommentKeywordsLabel, 
    decisionAttrList,
    sellingPoints,
    materialValueKey
  }) => {
    // quickship
    if (showQuickShip && quickship) {
      const { labelLang, suiIcon, fontColor } = quickship || {}
      swiperLabelList.push(
        textDataLabel({ 
          text: labelLang,
          suiIcon: template('16px', suiIcon),
          style: {
            color: fontColor
          }
        })
      )
      anaText.push('show_quickship')
    } else if (starComment && (showStarComment || props.showStarCommentSlot )) {
      // 星级评论处理
      swiperLabelList.push({
        type: 'star',
        random: Math.random(),
        starData: starComment
      })

      anaText.push(`${materialValueKey}_${starComment.comment_num_str}`)
    } else if (showUserBehaviorLabel && userBehaviorLabel?.labelLang) {
      // 用户行为标签
      swiperLabelList.push(
        textDataLabel({
          text: userBehaviorLabel.labelLang, 
          fontStyle: 'golden', 
          style: { 
            backgroundColor: userBehaviorLabel.backgroundColor, 
            color: userBehaviorLabel.fontColor
          }
        })
      )

      const _anaText = `show_behavior_label_${labelTypeMap.get(userBehaviorLabel.textType)}_${userBehaviorLabel.labelId}_${userBehaviorLabel?.labelLang}`
      
      anaText.push(_anaText)
      extraTagProposition.push(_anaText)
    } else if (showUserPreferencesLabel && userPreferencesLabel) {
      // 用户偏好标签
      swiperLabelList.push(
        textDataLabel({
          text: userPreferencesLabel.labelLang, 
          fontStyle: 'golden', 
          style: { 
            backgroundColor: userPreferencesLabel.backgroundColor, 
            color: userPreferencesLabel.fontColor
          }
        })
      )
    } else if (userCommentKeywordsLabel?.labelLang) {
      // 评论关键词标签
      swiperLabelList.push(
        textDataLabel({
          text: userCommentKeywordsLabel.labelLang, 
          fontStyle: 'golden' 
        })
      )
      anaText.push(`${materialValueKey}_${userCommentKeywordsLabel?.labelLang}`)
    } else if (showDecisionAttr && decisionAttrList?.length) {
      // 决策属性
      const displayDecisionAttrList = decisionAttrList.slice(0, 3).map(v => v.name)
      const text = displayDecisionAttrList.join(' | ')
      swiperLabelList.push(
        textDataLabel({
          text, 
          fontStyle: 'gray'
        })
      )

      const displayDecisionAttrListTextEn = decisionAttrList.slice(0, 3).map(v => v['name-en']).join(' | ')
      anaText.push(`${materialValueKey}_${displayDecisionAttrListTextEn}`)
    } else if (showSellingPoint && sellingPoints?.length) {
      // 人工卖点
      let tspIds = []
      let text = []
      sellingPoints.forEach(item => {
        if (swiperLabelList.length < 2 && item?.tag_val_name_lang) {
          swiperLabelList.push(
            textDataLabel({
              text: item.tag_val_name_lang, 
              fontStyle: 'gray'
            })
          )
          tspIds.push(`${materialValueKey}_${item.tag_val_id}`)
          text.push(item.tag_val_name_en)
        }
      })

      anaText.push(`${tspIds.join('_')}_${text.join('_')}`)
    }
  })
  
  return {
    swiperLabelList,
    ana: `show_label_Looping_${swiperLabelList.length}_${anaText.join('_')}`,
    extraTagProposition: extraTagProposition.join('|')
  }
}
// 轮播数据处理 end


const config = inject('config', () => {})
const constantData = inject('constantData', () => {})

const setAttrForAnalysis = inject('setAttrForAnalysis')

let swiperLabelList = ref([])

// 排行榜数据
const rankingList = computed(() => {
  return config.showRankingList && goodsInfo.value?.pretreatInfo?.[labelsFromKey]?.rankingList
})

// 榜单是否插入轮播
const addRankingLabelToSwiper = computed(() => {
  return config.addRankingLabelToSwiper && rankingList.value
})


const needToDisplay = computed(() => {
  return rankingList.value || swiperLabelList.value.length > 0
})

watch(
  () => goodsInfo.value.goods_sn,
  () => {
    let swiperLabelListNewValue = []

    const labelLoopingData = handelLabelData()

    if (rankingList.value  && addRankingLabelToSwiper.value) {
      // 榜单标签优先级最高 单独展示一行
      labelLoopingData.swiperLabelList.unshift({
        type: 'slot',
        random: Math.random(),
      })
      swiperLabelListNewValue = labelLoopingData.swiperLabelList.slice(0, 2)
    } else if (rankingList.value && !addRankingLabelToSwiper.value) {
      setAttrForAnalysis((el) => {
        el.setAttribute('data-ranking-list', rankingList.value.ana?.replace(/\s+/g, '_'))
      })
      return swiperLabelList.value = swiperLabelListNewValue
    }

    if (labelLoopingData?.swiperLabelList?.length > 0) {
      swiperLabelListNewValue = labelLoopingData.swiperLabelList
      setAttrForAnalysis((el) => {
        el.setAttribute('data-label-looping', labelLoopingData.ana?.replace(/\s+/g, '_') || '')
        el.setAttribute('data-extra-tag-proposition', labelLoopingData.extraTagProposition?.replace(/\s+/g, '_') || '')
      })
    }
    

    swiperLabelList.value = swiperLabelListNewValue
  },
  {
    immediate: true
  }
)


</script>


<style lang="less" scoped>
.product-card__selling-proposition-swiper {
  --swiper-container-height: 16px;
  height: var(--swiper-container-height, 16px);
}
.product-card__selling-proposition-container {
  .flexbox();
  .align-center();
  height: 16px;
  
  margin-top: 4px;
}
</style>
