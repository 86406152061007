<template>
  <div
    :class="[
      'product-card__color-set',
      {
        'product-card__color-set_vertical': true,
      },
    ]"
  >
    <ProductCardColorItem
      v-for="(color, index) in relatedColor.data.slice(0, 3)"
      :key="index"
      class="product-card__color-item"
      :index="index"
      :color-image="color.goods_color_image"
    />

    <div
      v-if="relatedColor.count > 3"
      class="product-card__color-count"
    >
      {{ relatedColor.count }}
    </div>
  </div>
</template>

<script>
import ProductCardColorItem from './ProductCardColorItem.vue'

export default {
  name: 'ProductCardColorSet',
  components: {
    ProductCardColorItem,
  },
  props: {
    relatedColor: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  inject: {
    item: {
      default: () => ({}),
    },
  },
  computed: {
    bindData() {
      return {
        color: this.relatedColor.data.map(item => item.goods_id).join('`') || '',
        goods_relation_id: this.item.value.goods_sn || '',
        position: `${this.item.value.index + 1}` || '',
      }
    }
  },
}
</script>

<style lang="less">
.product-card__color-set {
  border: 0.5px solid rgba(255, 255, 255, 0.30);
  border-radius: 5000px;
  background: rgba(0, 0, 0, 0.30);
  display: flex;
  margin-bottom: 12px;
  margin-right: 8.5px;
  margin-left: auto;
}
.product-card__color-set_vertical {
  padding: 2px;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  .product-card__color-count {
    line-height: normal;
  }
}
.product-card__color-count {
  font-size: 8px;
  color: #fff;
}
</style>
