
<!-- 目前只保留小号付费会员标签，大号标签废弃 -->
<template>
  <!-- 大号付费会员标签 -->
  <div 
    v-if="type == 'big'"
    class="shein-club-discount"
  >
    <div class="shein-club-discount__logo">
      <img
        :src="`${publicCdn}/she_dist/images/pay_vip/pay_vip_member_name@5x-9b50cbcf29.png`"
      />
    </div>
    <div class="shein-club-discount__discount">
      <div>-{{ discountValue }}%</div>
    </div>
  </div>

  <!-- 小号付费会员标签 -->
  <div 
    v-else-if="type === 'small'"
    class="shein-club-discount_small"
  >
    <img
      :src="`${publicCdn}/she_dist/images/pay_vip/sui_icon_paidmember_Label_s@5x-5dbacb188f.png`"
    />
    <div class="shein-club-discount__discount">
      <div>-{{ discountValue }}%</div>
    </div>
  </div>

  <!-- 小号付费会员标签新样式 -->
  <div 
    v-else-if="type === 'smallNew'" 
    class="product-card__sheinclub-discount--smallnew"
  >
    <i class="sh_pc_sui_icon sh_pc_sui_icon_club_logo_fill_15px_873c00"></i>
    <div class="sheinclub-discount__discount">
      -{{ discountValue }}%
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductCardSheinClubDiscount',
  inject: ['constantData'],
  props: {
    discountValue: {
      type: [String, Number],
      default: '',
    },
    /**
     * big: 大号付费会员标签
     * small: 小号付费会员标签
     * smallNew: 小号付费会员标签新样式
     */
    type: {
      type: String,
      default: 'big',
    },
  },
  computed: {
    publicCdn() {
      return this.constantData.PUBLIC_CDN
    }
  }
}
</script>

<style lang="less" scoped>
.shein-club-discount {
  position: relative;
  display: inline-block;
  height: 18px;
  line-height: 18px;
  font-size: 12px;
  margin-top: 0;
  background: #ffe2d3;
  border-radius: 1px;
  padding-left: 81px/* rtl:ignore */;
  direction: ltr/* rtl:ignore */;
  &_small {
    position: relative;
    display: inline-block;
    height: 16px;
    line-height: 16px;
    margin-top: 3px;
    font-size: 12px;
    padding-left: 18px /* rtl:ignore */;
    direction: ltr /* rtl:ignore */;
    > img {
      position: absolute;
      left: 0 /* rtl:ignore */;
      top: 0;
      width: 18px;
      height: 16px;
    }
    .shein-club-discount {
      &__discount {
        text-align: center;
        background: #ffe2d3;
        color: #873c00;

        > div {
          transform: scale(0.8333);
          direction: ltr/* rtl:ignore */;
        }
      }
    }
  }
  .shein-club-discount__logo {
    color: #873c00;
    margin: 0 4px 0 15px;
    > img {
      position: absolute;
      left: 0 /* rtl:ignore */;
      top: 50%;
      transform: translateY(-50%);
      width: 81px;
    }
  }
  &__discount {
    text-align: center;
    background: #873c00;
    color: #ffe2d3;
    padding: 0 2px;
    border-radius: 0 1px 1px 0 /* rtl:ignore */;
    > div {
      transform: scale(0.8333);
    }
  }
}

.product-card {
  /* stylelint-disable-next-line selector-class-pattern */
  &__sheinclub-discount--smallnew {
    flex: 0 0 auto;

    .flexbox();
    .align-center();

    position: relative;
    padding: 0 6px;
    height: 20px;
    
    line-height: 20px;
    border-radius: 2px;
    
    white-space: nowrap;
    background: #FFEFE6;

    font-size: 12px;
    color: @sui_color_club_rosegold_dark1;

    /* stylelint-disable-next-line selector-class-pattern */
    .sh_pc_sui_icon_club_logo_fill_15px_873c00 {
      margin-right: 4px;
    }

    .sheinclub-discount__discount {
      direction: ltr/* rtl:ignore */;
    }
  }
}
</style>
