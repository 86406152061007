<template>
  <div 
    v-if="needToDisplay"
    ref="horseRaceLabelRef"
    class="product-card__horse-race-container"
  >
    <!-- 榜单 单独展示 优先级最高 -->
    <ProductCardSellingPropositionRank
      v-if="displayMode === 'rank'"
      :ranking-list="rankingList"
    />

    <!-- 单行模式 -->
    <ProductCardAutoWrap
      v-if="displayMode === 'singleRow'"
      ref="autoWrapRef"
      v-expose="{
        id: '2-3-42',
        data: {
          analysisCb: analysisData
        }
      }"
      :rows="swiperLabelList"
      :high-light-row="0"
      :row-height="SLIDE_HEIGHT"
      :is-mounted="isMounted"
    >
      <ProductCardSellingPropositionRank
        :style="{
          '--selling-rank-height': `${SLIDE_HEIGHT}`, '--selling-rank-margin-top': '0'
        }"
        :ranking-list="rankingList"
      />
    </ProductCardAutoWrap>
    
    <!-- 多行模式 -->
    <Swiper
      v-if="displayMode === 'swiper'"
      class="product-card__horse-race-swiper"
      :delay-loop-init-time="1500"
      :delay="3000"
      direction="vertical"
      intersection-observer
      @loop-play-start="handleLoopPlayStart"
    >
      <swiper-item
        v-for="(slideIndex, index) in SLIDE_ROW"
        :key="slideIndex"
        ref="swiperItemRef"
      >
        <ProductCardAutoWrap
          ref="autoWrapRef"
          :rows="swiperLabelList"
          :high-light-row="index"
          :row-height="SLIDE_HEIGHT"
          :is-mounted="isMounted"
        >
          <ProductCardSellingPropositionRank
            :style="{
              '--selling-rank-height': `${SLIDE_HEIGHT}`, '--selling-rank-margin-top': '0'
            }"
            :ranking-list="rankingList"
          />
        </ProductCardAutoWrap>
      </swiper-item>
    </Swiper>
  </div>
</template>

<script name="ProductCardHorseRaceLabelContainer" setup>
import { template } from '@shein/common-function'

import ProductCardSellingPropositionRank from './ProductCardSellingPropositionRank.vue'
import Swiper from 'public/src/pages/components/Swiper/Swiper.vue'
import swiperItem from 'public/src/pages/components/Swiper/SwiperItem.vue'
import ProductCardAutoWrap from './ProductCardAutoWrap.vue'
import { exposeHorseRaceLabel } from '../../analysis/index'

import { ref, inject, watch, computed, toRefs, onMounted } from 'vue'

const props = defineProps({
  goodsInfo: {
    type: Object,
    default: () => ({}),
  },
})

const labelsFromKey = inject('labelsFromKey')
const item = inject('item', () => {})

// 被轮播标签的高度
const SLIDE_HEIGHT = 16
// 轮播的行数
const SLIDE_ROW = 2
// 多少个标签为一行
const ONE_LINE_LABEL_NUM = 2
// 最多显示标签数量
const MAX_LABEL_NUM = 4

const { goodsInfo } = toRefs(props)

const textDataLabel = ({ text, fontStyle, style = {}, icon = '', labelLangEn = '', index, tagId, suiIcon }) => {
  return {
    type: 'text',
    random: Math.random(),
    style: style,
    textData: {
      text,
      fontStyle,
      icon,
      tagId,
      labelLangEn,
      suiIcon,
    },
    labelIndex: index
  }
}

const getRelativeOffsetTop = (element, parentElement) => {
  if (!element || !parentElement) return
  const rect = element.getBoundingClientRect()
  const parentRect = parentElement.getBoundingClientRect()
  // 向下取整
  return ~~(rect.top - parentRect.top)
}

const getLabelHeight = (label) => {
  // 向下取整
  return ~~(label?.getBoundingClientRect().height)
}

/**
 * 处理赛马标签
 */
const handelLabelData = () => {
  const { pretreatInfo = {} } = goodsInfo.value ?? {}
  const { showQuickShip } = config ?? {}
  // 来自赛马的标签数据
  const horseRaceLabel = pretreatInfo?.horseRaceLabel ?? []

  if (!horseRaceLabel.length) {
    return {
      swiperLabelList: [],
    }
  }

  const swiperLabelList = horseRaceLabel?.slice(0, MAX_LABEL_NUM).map(({ 
    starComment,
    userBehaviorLabel, 
  }, index) => {
    // 星级评论处理
    if (starComment) {
      return {
        type: 'star',
        random: Math.random(),
        starData: starComment,
        labelIndex: index
      }
    }

    // 用户行为标签
    const { labelLang, backgroundColor, fontColor, icon, labelLangEn, tagId, suiIcon, labelCustomType } = userBehaviorLabel
    const isQuickShip = labelCustomType == 'quickship'
    const showLabel = isQuickShip ? showQuickShip : true // 是quickship标签时，需要由showQuickShip控制是否展示
    if (showLabel && userBehaviorLabel?.labelLang) {
      return textDataLabel(
        {
          index,
          text: labelLang,
          fontStyle: 'golden',
          style: {
            backgroundColor: backgroundColor,
            color: fontColor
          },
          icon,
          labelLangEn,
          tagId,
          suiIcon: isQuickShip ? template('16px', suiIcon) : suiIcon
        }
      )
    }
  }).filter(Boolean)
  
  return {
    swiperLabelList,
  }
}

const config = inject('config', () => {})
const setAttrForAnalysis = inject('setAttrForAnalysis')
const swiperLabelList = ref([])
const displayMode = ref('')
const isMounted = ref(false)
const autoWrapRef = ref(null)
const needToDisplay = ref(false)
const swiperItemRef = ref(null)
const horseRaceLabelRef = ref(null)
const isInViewPort = ref(false)
const exposeSwitch = ref([])

// 排行榜数据
const rankingList = computed(() => {
  return config.showRankingList && goodsInfo.value?.pretreatInfo?.[labelsFromKey]?.rankingList
})

// 榜单是否插入轮播
const addRankingLabelToSwiper = computed(() => {
  return config.addRankingLabelToSwiper && rankingList.value
})

const analysisData = () => {
  if (!isMounted.value) return

  const autoWrapElement = autoWrapRef.value?.$el
  const sellingPointsLabels = autoWrapElement?.querySelectorAll('.product-card__auto-label') || []
  let exposeHorseRaceLabelData = {
    targets: [],
    goods_id: item.value?.goods_id
  }

  Array.from(sellingPointsLabels).forEach((label) => {
    if (!label) return

    const offsetTop = getRelativeOffsetTop(label, autoWrapElement)
    const labelHeight = 0
    if (offsetTop === labelHeight) {
      exposeHorseRaceLabelData.targets.push(label)
    }
  })
  exposeHorseRaceLabelData.targets.length > 0 && exposeHorseRaceLabel(exposeHorseRaceLabelData)
}

const switchDisplayMode = () => {
  const autoWrapElement = autoWrapRef.value?.$el
  const getHorseRaceLabels = autoWrapElement?.querySelectorAll('.product-card__auto-wrap > .product-card__auto-label') || []

  if (getHorseRaceLabels.length === 0) {
    return
  }

  const renderFirstSlide = []
  const renderSecondSlide = []

  Array.from(getHorseRaceLabels).forEach((label, index) => {
    const labelOffsetTop = getRelativeOffsetTop(label, autoWrapElement)
    const labelHeight = getLabelHeight(label)

    const swiperLabelListFilted = swiperLabelList.value.map(item => item.type === 'slot' || item.type === 'gap' ? undefined : item).filter(Boolean)

    if (labelOffsetTop === 0) {
      swiperLabelListFilted[index] && renderFirstSlide.push(swiperLabelListFilted[index])
    }
    
    if (labelOffsetTop === labelHeight) {
      swiperLabelListFilted[index] && renderSecondSlide.push(swiperLabelListFilted[index])
    }
  })

  const firstSlideItems = []
  renderFirstSlide.forEach((item, index) => {
    if (index === 1 || index === 3) {
      firstSlideItems.push(item)
      firstSlideItems.push({ type: 'br' })
    } else {
      firstSlideItems.push(item)
    }
  })

  const secondSlideItems = []
  renderSecondSlide.forEach((item, index) => {
    if (index === 1 || index === 3) {
      secondSlideItems.push(item)
      secondSlideItems.push({ type: 'br' })
    } else {
      secondSlideItems.push(item)
    }
  })

  // 每2个标签添加 '|' ui 展示逻辑
  const allSlideItems = []

  firstSlideItems.forEach((item, index) => {
    if (item.type !== 'br' && firstSlideItems[index + ONE_LINE_LABEL_NUM]?.type === 'br') {
      allSlideItems.push(item)
      allSlideItems.push({ type: 'line' })
    } else {
      allSlideItems.push(item)
    }
  })

  secondSlideItems.forEach((item, index) => {
    if (item.type !== 'br' && secondSlideItems[index + ONE_LINE_LABEL_NUM]?.type === 'br') {
      allSlideItems.push(item)
      allSlideItems.push({ type: 'line' })
    } else {
      allSlideItems.push(item)
    }
  })

  const swiperLabelListFilted = swiperLabelList.value.map(item => item.type === 'slot' || item.type === 'gap' ? undefined : item).filter(Boolean)

  if (swiperLabelListFilted.length > ONE_LINE_LABEL_NUM || renderSecondSlide.length > 0) {
    if (rankingList.value && addRankingLabelToSwiper.value) {
      allSlideItems.unshift({
        type: 'slot',
        random: Math.random(),
      })
    }
    displayMode.value = 'swiper'
  }

  swiperLabelList.value = allSlideItems 
  exposeSwitch.value = new Array(swiperLabelList.value.length).fill(false)

  isMounted.value = true
}

onMounted(() => {

  // 延迟计算一方面减少有利于首屏
  // 另一方面 sui-drawer 弹窗内展示需要延迟计算，避免dom还没有渲染导致计算错误
  setTimeout(() => {
    switchDisplayMode()
  }, 500)

  if(displayMode.value === 'swiper'){
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          isInViewPort.value = true
        } else {
          isInViewPort.value = false
        }
      })
    })
    observer.observe(horseRaceLabelRef.value) 
  }
})

watch(
  () => goodsInfo.value.goods_sn,
  () => {
    const labelLoopingData = handelLabelData()

    if (rankingList.value && addRankingLabelToSwiper.value) {
      // 榜单标签优先级最高 单独展示一行
      labelLoopingData.swiperLabelList.unshift({
        type: 'slot',
        random: Math.random(),
      })
      swiperLabelList.value = labelLoopingData.swiperLabelList.slice(0, MAX_LABEL_NUM)
      exposeSwitch.value = new Array(swiperLabelList.value.length).fill(false)
      setAttrForAnalysis((el) => {
        el.setAttribute('data-ranking-list', rankingList.value.ana?.replace(/\s+/g, '_'))
      })

      displayMode.value = 'singleRow'
      needToDisplay.value = true
      return
    }
    
    if (rankingList.value) {
      setAttrForAnalysis((el) => {
        el.setAttribute('data-ranking-list', rankingList.value.ana?.replace(/\s+/g, '_'))
      })

      displayMode.value = 'rank'
      needToDisplay.value = true
      return
    }


    if (labelLoopingData.swiperLabelList.length === 0) {
      return
    }

    // 初始化渲染时添加一条特殊的线: 针对第2个标签
    swiperLabelList.value = [labelLoopingData.swiperLabelList[0], { type: 'gap' }, ...labelLoopingData.swiperLabelList.slice(1)]
    displayMode.value = 'singleRow'
    needToDisplay.value = true
  },
  {
    immediate: true
  }
)

const handleLoopPlayStart = ({ index }) => {
  if (!config.showHorseRaceLabel) {
    return
  }

  const slideItemRef = swiperItemRef.value?.[index]
  if (slideItemRef) {
    const slideItemRefEl = slideItemRef.$el
    const sellingPointsLabels = slideItemRefEl?.querySelectorAll('.product-card__auto-wrap > .product-card__auto-label') || []
    const labelWrap = slideItemRefEl?.querySelector('.product-card__auto-wrap')


    let exposeHorseRaceLabelData = {
      targets: [],
      goods_id: item.value?.goods_id
    }

    Array.from(sellingPointsLabels).forEach((label) => {
      if (!label) return

      const offsetTop = getRelativeOffsetTop(label, labelWrap)
      const labelHeight = getLabelHeight(label) * index
      if (offsetTop === labelHeight) {
        exposeHorseRaceLabelData.targets.push(label)
      }
    })
    if (!exposeSwitch.value[index] && isInViewPort.value) {
      exposeSwitch.value[index] = true
      exposeHorseRaceLabelData.targets.length > 0 && exposeHorseRaceLabel(exposeHorseRaceLabelData)
    }
  }
}



</script>


<style lang="less" scoped>
.product-card__horse-race-swiper {
  --swiper-container-height: 16px;
  height: var(--swiper-container-height, 16px);
}
.product-card__horse-race-container {
  .flexbox();
  .align-center();
  height: 16px;
  
  margin-top: 4px;
  overflow: hidden; // 隐藏每个标签默认都加上的 '|' 线
}
</style>
